import './App.scss';
import AppRouter from './AppRouter';
import { isPhonexaMake, isPhonexaSource } from './constants';
import { getTemplateId } from './services/shared';
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

window.addEventListener( "popstate", function ( event ) {
	window.location.reload();
});


function App() {
	const templateId = getTemplateId();
	const history=useHistory()
	useEffect(()=>{
		let searchParams = new URLSearchParams(window.location.search);
		let tmpl = searchParams.get('tmpl');

		if (tmpl !== 't2' && tmpl!=='t3' && tmpl!=='t4') {
			searchParams.set('tmpl', 't1');
		}
			// Set "new" as true, when Phonexa condition is met
			if(isPhonexaMake()){
				searchParams.set('new', 'true');
			} else {
				searchParams.delete("new");
			}
			let newUrl = window.location.pathname + '?' + searchParams.toString();
			history.push(newUrl);
		
	},[])
	return (
		<>
			<div className={templateId}>
				<AppRouter templateId={templateId}></AppRouter>
			</div>
			
		</>
	);
}

export default App;
